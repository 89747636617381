import React, { lazy } from "react";
import { withLayout } from "../../components/layout/withLayoutHOC";

const Home = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.Home })))
);

const WhyArtswap = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.WhyArtswap }))
  )
);

const PrivacyPolicy = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.PrivacyPolicy }))
  )
);

const TermsAndConditions = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.TermsAndConditions }))
  )
);

const About = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.About })))
);

const Inquiry = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.Inquiry })))
);

const Profile = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Profile.Profile,
    }))
  )
);

const EditProfile = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Profile.EditProfile,
    }))
  )
);

const CreateArt = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Art.CreateArt,
    }))
  )
);

const DetailArt = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Art.DetailArt,
    }))
  )
);

const EditArt = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Art.EditArt,
    }))
  )
);

const ExploreArt = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Art.ExploreArt,
    }))
  )
);

const CreateCollection = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Collection.CreateCollection,
    }))
  )
);

const DetailCollection = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Collection.DetailCollection,
    }))
  )
);

const EditCollection = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Collection.EditCollection,
    }))
  )
);

const ExploreCollection = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Collection.ExploreCollection,
    }))
  )
);

const ArtistDetail = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Artist.ArtistDetail,
    }))
  )
);

const ExploreArtist = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Artist.ExploreArtist,
    }))
  )
);

const APP_ROUTES = [
  {
    path: "/",
    element: <Home />,
    protected: false,
  },
  {
    path: "/why-artswap",
    element: <WhyArtswap />,
    protected: false,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    protected: false,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
    protected: false,
  },
  {
    path: "/about",
    element: <About />,
    protected: false,
  },
  {
    path: "/call-to-action",
    element: <Inquiry />,
    protected: false,
  },
  {
    path: "/profile",
    element: <Profile />,
    protected: false,
  },
  {
    path: "/edit-profile",
    element: <EditProfile />,
    protected: false,
  },
  {
    path: "/create-art",
    element: <CreateArt />,
    protected: false,
  },
  {
    path: "/arts/:art_id",
    element: <DetailArt />,
    protected: false,
  },
  {
    path: "/edit-art/:art_id",
    element: <EditArt />,
    protected: false,
  },
  {
    path: "/explore-arts",
    element: <ExploreArt />,
    protected: false,
  },
  {
    path: "/create-collection",
    element: <CreateCollection />,
    protected: false,
  },
  {
    path: "/collections/:collection_id",
    element: <DetailCollection />,
    protected: false,
  },
  {
    path: "/edit-collection/:collection_id",
    element: <EditCollection />,
    protected: false,
  },
  {
    path: "/explore-collections",
    element: <ExploreCollection />,
    protected: false,
  },
  {
    path: "/artists/:artist_id",
    element: <ArtistDetail />,
    protected: false,
  },
  {
    path: "/explore-artists",
    element: <ExploreArtist />,
    protected: false,
  },
];

export default APP_ROUTES;
